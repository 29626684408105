import "./tablet.css";

function Tablet({ image }) {
    return (
        <div class="tablet">
            <img className="tablet_image" src={image} alt="tablet" />
        </div>
    );
}

export default Tablet;
