import "./desktop.css";

function Desktop({ image }) {
    return (
        <div class="stand">
            <div class="monitor">
                <img className="monitor_image" src={image} alt="desktop" />
            </div>
        </div>
    );
}

export default Desktop;
