import "./portfolio.css";
import Desktop from "./desktop/Desktop";
import Mobile from "./mobile/Mobile";
import Tablet from "./tablet/Tablet";
import { useState } from "react";

const projects = [
    {
        name: "Pizza Company",
        link: "https://pizza-omega-six.vercel.app/",
        mobile: "/mobile/pizza.png",
        desktop: "/desktop/pizza.png",
        tablet: "/tablet/pizza.png",
        github: "https://github.com/10urkarasu/pizza",
        using: "React, Vite, Tailwindcss, Gelolocation, Redux Toolkit, React Router, React Router Forms, useFetcher, Data Fetching, Reusable components, ",
    },
    {
        name: "WorldWise",
        link: "https://world-wise-murex.vercel.app/",
        mobile: "/mobile/worldwise.png",
        desktop: "/desktop/worldwise.png",
        tablet: "/tablet/worldwise.png",
        github: "https://github.com/10urkarasu/world-wise",
        using: "React, Vite, Leaflet, Gelolocation, Context Api, React Router, Json-server",
    },
    {
        name: "usePopCorn",
        link: "https://usepopcorn-gamma.vercel.app/",
        mobile: "/mobile/usepopcorn.png",
        desktop: "/desktop/usepopcorn.png",
        tablet: "/tablet/usepopcorn.png",
        github: "https://github.com/10urkarasu/usepopcorn",
        using: "React, Omdb Api, Custom Hooks, Reusable Component, Locale Storage, Data Fetching",
    },
    {
        name: "React Quiz",
        link: "https://quiz-app-plum-one.vercel.app/",
        mobile: "/mobile/reactquiz.png",
        desktop: "/desktop/reactquiz.png",
        tablet: "/tablet/reactquiz.png",
        github: "https://github.com/10urkarasu/quiz-app",
        using: "React, Json-server, Reducer, Data Fetching",
    },
];
function Portfolio() {
    const [project, setProject] = useState(0);
    return (
        <section className="section" id="portfolio">
            <h2 className="section__title">Portfolio</h2>
            <span className="section__subtitle">My portfolio projects</span>
            <div className="slide">
                <i
                    className="uil uil-angle-left-b  portfolio_icon"
                    onClick={() =>
                        project > 0
                            ? setProject(project - 1)
                            : setProject(projects.length - 1)
                    }
                ></i>
                <h1 className="project_title">{projects[project].name}</h1>
                <i
                    className="uil uil-angle-right-b portfolio_icon"
                    onClick={() =>
                        project < projects.length - 1
                            ? setProject(project + 1)
                            : setProject(0)
                    }
                ></i>
            </div>
            <div className="mockup">
                <Mobile image={projects[project].mobile} />
                <Desktop image={projects[project].desktop} />
                <Tablet image={projects[project].tablet} />
            </div>
            <div className="portfoliodesc">
                <span>
                    <b>Used : </b>
                    {projects[project].using}
                </span>
                <span>
                    <b>Source Code : </b>
                    <a target="_blank" href={projects[project].github}>
                        {projects[project].github}
                    </a>
                </span>
                <span>
                    <b>Demo : </b>
                    <a target="_blank" href={projects[project].link}>
                        {projects[project].link}
                    </a>
                </span>
            </div>
        </section>
    );
}

export default Portfolio;
