import "./mobile.css";

function Mobile({ image }) {
    return (
        <div class="mobile">
            <img className="mobile_image" src={image} alt="mobile" />
        </div>
    );
}

export default Mobile;
