import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer__container container">
            <h1 className="footer__title">
                Onur
            </h1>
            <ul className="footer__list">
                <li>
                    <a href="#home" className="footer__link">Home</a>
                </li>
                <li>
                    <a href="#about" className="footer__link">About</a>
                </li>
                <li>
                    <a href="#skills" className="footer__link">Skills</a>
                </li>
                <li>
                    <a href="#contact" className="footer__link">Contact</a>
                </li>
            </ul>
            <div className="footer__social">
            <a href="https://www.instagram.com/10urkarasu/" className="footer__social-link" target="_blank">
                <i className="bx bxl-instagram"></i>
            </a>
            <a href="https://twitter.com/onurkarasu_37" className="footer__social-link" target="_blank">
                <i className="bx bxl-twitter"></i>
            </a>
            <a href="https://www.linkedin.com/in/onur-karasu1/" className="footer__social-link" target="_blank">
                <i class="bx bxl-linkedin"></i>
            </a>
            <a href="https://github.com/10urkarasu" className="footer__social-link" target="_blank">
                <i className="bx bxl-github"></i>
            </a>
            </div>
            <span className='footer__copy'>&#169;Onur KARASU</span>
        </div>
    </footer>
  )
}

export default Footer
