import React, { useState } from 'react'
import './qualification.css';

const Qualification = () => {
    const [toggleState, setToggleState] = useState(1);
    const toggleTab=(index)=>{
        setToggleState(index);
    }
    return (
        <section className="qualification section">
            <h2 className="section__title">Qualification</h2>
            <span className="section__subtitle">My personel journey</span>
            <div className="qualification__container container">
                <div className="qualification__tabs">
                    <div onClick={()=>toggleTab(1)} className={toggleState===1 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"}>
                        <i className="uil uil-graduation-cap qualification__icon"></i>{" "}
                        Education
                    </div>

                    <div onClick={()=>toggleTab(2)} className={toggleState===2 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"}>
                        <i className="uil uil-briefcase-alt qualification__icon"></i>{" "}
                        Experience
                    </div>
                </div>

                <div className="qualification__sections">
                    <div className={toggleState===1 ? "qualification__content qualification__content-active" : "qualification__content "}>
                        

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Selçuk University</h3>
                                <span className="qualification__subtitle">Computer engineering - Licence</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i> 2018 - 2022
                                </div>
                            </div>
                            <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div></div>
                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">Arnavutköy Anatolian High School</h3>
                                <span className="qualification__subtitle"></span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i> 2013 - 2017
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={toggleState===2 ? "qualification__content qualification__content-active" : "qualification__content "}>
                        
                        <div className="qualification__data">
                            <div></div>
                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">Application Developer</h3>
                                <span className="qualification__subtitle">Full Time - MsSQL, .NET, RPA, Qlik Sense </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i> 2022 - Present
                                </div>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Application Developer</h3>
                                <span className="qualification__subtitle">Intern Engineer</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i> 2022
                                </div>
                            </div>
                            <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                            </div>
                            
                        </div>

                        <div className="qualification__data">
                        <div></div>
                        <div>

                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">Game Developer</h3>
                                <span className="qualification__subtitle">Summer Internship</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i> 2021
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Qualification